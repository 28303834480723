import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import { markedMissingTotes, splitRoute } from "features/route/routeAction";
import "component/dailyRoaster/AddDuty.scss";
import { CustomModal } from "component/common/Modal/CustomModal";

export const SplitRoute = ({
  isModelOpen,
  handleClose,
  route,
  type,
  title,
}) => {
  const dispatch = useDispatch();
  const [checkedTotes, setCheckedTotes] = useState([]);
  const totes = route?.routeWidgetVM?.assignedToteIds;

  const handleFormSubmit = () => {
    let data;

    if (type === "split") {
      data = {
        routeId: route?.routeWidgetVM?.routeId,
        toteToKeepInOriginalRoute: checkedTotes,
        toteToRemoveFromRoute: totes?.filter(
          (tote) => !checkedTotes.includes(tote)
        ),
      };
      dispatch(splitRoute(data));
    } else if (type === "missing") {
      data = {
        routeId: route?.routeWidgetVM?.routeId,
        toteToKeepInOriginalRoute: totes?.filter(
          (tote) => !checkedTotes.includes(tote)
        ),
        toteToRemoveFromRoute: checkedTotes,
      };
      dispatch(markedMissingTotes(data));
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const toteId = name;
    const currentIndex = totes.indexOf(toteId);

    if (checked) {
      // Add the checked toteId to the list
      setCheckedTotes((prevState) => [...prevState, toteId]);
    } else {
      // Uncheck the current and all subsequent checkboxes
      setCheckedTotes((prevState) =>
        prevState.filter((tote, index) => index < currentIndex)
      );
    }
  };

  return (
    <CustomModal open={isModelOpen} onClose={handleClose}>
      <div className="add-duty-modal">
        <div>
          <span className="title" data-testid={`modal-title`}>
            {title}
          </span>
        </div>
        <div>
          <span>
            <span data-testid={`route-id label`}>
              Route ID : {route?.routeWidgetVM?.routeId}
            </span>
          </span>
          <FormGroup data-testid={`form-allTotes`}>
            {totes?.map((toteId, index) => {
              const isChecked = checkedTotes.includes(toteId);
              const isDisabled =
                type === "split" &&
                index > 0 &&
                !checkedTotes.includes(totes[index - 1]);

              return (
                <FormControlLabel
                  key={toteId}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      name={toteId}
                      disabled={isDisabled}
                      data-testid={`tote-${index + 1} checkbox`}
                    />
                  }
                  label={toteId}
                  data-testid={`tote-${index + 1}`}
                />
              );
            })}
          </FormGroup>
        </div>
        <div className="button-container">
          <Button
            onClick={handleFormSubmit}
            disabled={!checkedTotes?.length}
            variant="outlined"
            data-testid={`save-button`}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            data-testid={`cancel-button`}
          >
            Cancel
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

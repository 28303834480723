import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/authAction'
import { getRoute, fetchRoutes, fetchLiveTrackingRoutes, rejectRoute, getCurrentLocation, getRouteHistoricData, removeRoute, fetchLiveTrackingSummary, rescueRoute, fetchReturnTerminalData, rescheduleRoute, markedMissingTotes, fetchRouteStopDetails, fetchRouteSummary, markRouteTerminate, splitRoute, sendSOSAcceptance, breakDownRoute, getRouteSequence } from './routeAction';

const initialState = {
  data: {},
  historicData: {},
  sequenceData: {},
  loading: false,
  error: null,
  routes: [],
  routeCount: 0,
  liveTrackingRoutes: [],
  routeSuccess: false,
  routeFollowed: [],
  successMessage: null,
  routeSummary: [],
  returnTerminalData: [],
  rescheduleRouteSuccess: false,
  routeStopDetails: null,
  routeSummmary: [],
  terminateRouteSuccess: false
}

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    resetSuccessState: (state) => {
      state.routeSuccess = false;
      state.successMessage = null;
      state.rescheduleRouteSuccess = false
    },
    resetError: (state) => {
      state.error = null;
    },
    resetData: (state) => {
      state.historicData = {};
      state.data = {};
      state.sequenceData = {}
    },
    resetStopDetails: (state) => {
      state.routeStopDetails = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRoute.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
        const { routeId } = action.payload?.dailyRouteScanSummaryVM;
        state.data[routeId] = action.payload;
      })
      .addCase(getRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Get route Sequence 
      .addCase(getRouteSequence.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRouteSequence.fulfilled, (state, action) => {
        state.loading = false;
        console.log('===payload', action.payload)
        const routeId = action?.payload?.id // Generates a random number between 100 and 999

          // const { routeId } = action.payload?.dailyRouteScanSummaryVM || Math.floor(Math.random() * 900) + 100; // Generates a random number between 100 and 999
          ;
        state.sequenceData[routeId] = action.payload?.response;
      })
      .addCase(getRouteSequence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // All routes
      .addCase(fetchRoutes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoutes.fulfilled, (state, action) => {
        state.loading = false;
        state.routes = action?.payload?.routes
        state.routeCount = action?.payload?.totalCount
      })
      .addCase(fetchRoutes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      // Routes Summary
      .addCase(fetchRouteSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRouteSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSummmary = action?.payload
      })
      .addCase(fetchRouteSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Live tracking routes
      .addCase(fetchLiveTrackingRoutes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLiveTrackingRoutes.fulfilled, (state, action) => {
        state.loading = false;
        state.liveTrackingRoutes = action?.payload?.routes
      })
      .addCase(fetchLiveTrackingRoutes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Reject route
      .addCase(rejectRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSuccess = true
      })
      .addCase(rejectRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Remove short from  route
      .addCase(removeRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSuccess = true
        state.successMessage = action?.payload
      })
      .addCase(removeRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Get current location
      .addCase(getCurrentLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentLocation.fulfilled, (state, action) => {
        state.loading = false;
        // state.routeFollowed = action?.payload
        state.routeFollowed = action?.payload?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
      })
      .addCase(getCurrentLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Get live tracking summary
      .addCase(fetchLiveTrackingSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLiveTrackingSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSummary = action?.payload
      })
      .addCase(fetchLiveTrackingSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Rescue route
      .addCase(rescueRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rescueRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSuccess = true
        state.successMessage = action?.payload
      })
      .addCase(rescueRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Return terminal data 
      .addCase(fetchReturnTerminalData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReturnTerminalData.fulfilled, (state, action) => {
        state.loading = false;
        state.returnTerminalData = action?.payload?.routes
        state.routeCount = action?.payload?.totalCount
      })
      .addCase(fetchReturnTerminalData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Reschedule route
      .addCase(rescheduleRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rescheduleRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.rescheduleRouteSuccess = true
      })
      .addCase(rescheduleRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Missing tote
      .addCase(markedMissingTotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markedMissingTotes.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSuccess = true
        state.successMessage = action?.payload
      })
      .addCase(markedMissingTotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(logout.fulfilled, (state) => {
        return initialState;
      })

      // Fetch route stop details
      .addCase(fetchRouteStopDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRouteStopDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.routeStopDetails = action?.payload
      })
      .addCase(fetchRouteStopDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //terminate route
      .addCase(markRouteTerminate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markRouteTerminate.fulfilled, (state, action) => {
        state.loading = false;
        state.terminateRouteSuccess = true
        state.successMessage = action.payload
      })
      .addCase(markRouteTerminate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //split route
      .addCase(splitRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(splitRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSuccess = true;
        state.successMessage = action.payload
      })
      .addCase(splitRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
      })

      //Breakdown route
      .addCase(breakDownRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(breakDownRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeSuccess = true;
      })
      .addCase(breakDownRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
      })

      // Get historic data
      .addCase(getRouteHistoricData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRouteHistoricData.fulfilled, (state, action) => {
        state.loading = false;
        const routeId = action?.payload?.routeId;
        state.historicData[routeId] = action.payload?.data;
      })
      .addCase(getRouteHistoricData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get historic data
      .addCase(sendSOSAcceptance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendSOSAcceptance.fulfilled, (state) => {
        state.loading = false;
        state.routeSuccess = true;
      })
      .addCase(sendSOSAcceptance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  }
})

export const { resetSuccessState, resetError, resetData, resetStopDetails } = routeSlice.actions;
export default routeSlice.reducer;
// DataTable.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTable, useExpanded, useSortBy, useRowSelect } from 'react-table';
import { get} from 'lodash';
import _ from 'lodash';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, Checkbox } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableColumnHeader from './DraggableColumnHeader';
import DraggableRow from './DraggableRow';
import CollapsibleRow from './CollapsibleRow';
import { TablePagination } from './tablePagination';
import './dataTable.scss';

const fetchDataByNestedKey = (row, nestedKey) => {
  const keys = nestedKey?.split('.');
  let data = row;
  for (const key of keys) {
    if (data && typeof data === 'object' && key in data) {
      data = data[key];
    } else {
      return undefined;
    }
  }
  return data;
};

const customSort = (rowA, rowB, columnId, desc) => {
  const a = fetchDataByNestedKey(rowA?.original, columnId);
  const b = fetchDataByNestedKey(rowB?.original, columnId);

  // Handle different data types
  if (typeof a === 'string' && typeof b === 'string') {
    return a?.localeCompare(b); // Case-insensitive string comparison
  } else if (!isNaN(a) && !isNaN(b)) {
    return a - b; // Numeric comparison
  } else if (typeof a === 'object' && typeof b === 'object') {
    // For values wrapped in Link tags or other components
    return a?.props?.children?.localeCompare(b?.props?.children);
  }

  return 0;
};

const DataTable = ({
  columns: initialColumns,
  data,
  rowKey,
  childComponent: ChildComponent,
  isCollpsable,
  showPagination,
  onPageChange,
  page,
  totalRowsCount,
  onSortChange,
  uniqueKey,
  setUpdatedColumns,
  handleChangeRowsPerPage,
  rowsPerPage,
  collapsibleColumnLabel,
  allowSelect = false,
  onSelectClick,
  resetSelectedRows,
  isDraggable = false,
  updateRowSequence,
  isChildComponentExpanded = false,
  allowSorting,
  maxHeight,
  dataTestId
}) => {
  const [columns, setColumns] = useState(initialColumns.map((col) => ({
    ...col,
    sortType: (rowA, rowB, columnId, desc) => customSort(rowA, rowB, columnId, desc)
  })));
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [tableData, setTableData] = useState(data);
  const [isColumnOrderChanged, setIsColumnOrderChanged] = useState(false);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: page, pageSize: 10 },
    },
    useSortBy,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    //Prevent multiple rerendering
  if (!_.isEqual(data, tableData)) {
      setTableData(data);
    }
  }, [data, tableData]);

  const moveRow = useCallback(
    (sourceIndex, targetIndex) => {
      const updatedData = [...tableData];
      const draggedRow = updatedData[sourceIndex];
      updatedData[sourceIndex] = updatedData[targetIndex];
      updatedData[targetIndex] = draggedRow;
      setTableData(updatedData);
      updateRowSequence(sourceIndex, targetIndex)
    },
    [tableData]
  );

  const isAllSelected = useMemo(
    () => rows.length > 0 && Object.keys(selectedRowIds).length === rows.length && Object.values(selectedRowIds).every(Boolean),
    [selectedRowIds, rows]
  );

  useEffect(() => {
    if (onSelectClick) {
      const ids = Object.keys(selectedRowIds);
      onSelectClick(ids);
    }
  }, [selectedRowIds]);

  useEffect(() => {
      setSelectedRowIds({});
  }, [resetSelectedRows]);

  useEffect(() => {
    if (setUpdatedColumns) {
      setUpdatedColumns(columns);
    }
  }, [columns]);

  const handleSelectAll = () => {
    const allRowIds = rows?.map((row) => fetchDataByNestedKey(row.original, uniqueKey));
    setSelectedRowIds((prevSelectedRowIds) => {
      if (Object.keys(prevSelectedRowIds).length === allRowIds?.length) {
        return {};
      } else {
        const newSelectedRowIds = {};
        allRowIds?.forEach((id) => {
          newSelectedRowIds[id] = true;
        });
        return newSelectedRowIds;
      }
    });
  };

  const handleRowSelect = (id) => {
    setSelectedRowIds((prevSelectedRowIds) => {
      if (prevSelectedRowIds[id]) {
        const { [id]: removedId, ...rest } = prevSelectedRowIds;
        return rest;
      } else {
        return { ...prevSelectedRowIds, [id]: true };
      }
    });
  };

  // Column reordering logic
  const updateColumnOrder = (dragIndex, hoverIndex) => {
    const updatedColumns = [...columns];
    const draggedColumn = updatedColumns[dragIndex];

    updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(hoverIndex, 0, draggedColumn);

    setColumns(updatedColumns);

    if (JSON.stringify(columns) !== JSON.stringify(updatedColumns)) {
      if(setUpdatedColumns){
        setUpdatedColumns(updatedColumns); // Update parent state with new column order
      }

      setIsColumnOrderChanged(true); // Mark column order as changed
    }
  };

  useEffect(() => {
    if (onSortChange) {
      const sortedOriginalRows = rows.map((sortedRow) => {
        const originalRow = tableData.find((item) => get(item, uniqueKey) === get(sortedRow.original, uniqueKey));
        return originalRow;
      });
      onSortChange(sortedOriginalRows);
    }
  }, [tableData, rows, onSortChange, uniqueKey]);

  useEffect(() => {
    // Only update columns if there is an actual difference between columns and initialColumns
    const initialColumnsString = JSON.stringify(initialColumns);
    const currentColumnsString = JSON.stringify(columns);

    // If columns have not been manually reordered and initialColumns are different from current columns
    if (!isColumnOrderChanged && initialColumnsString !== currentColumnsString) {
      setColumns(initialColumns);
    }
  }, [initialColumns, isColumnOrderChanged, columns]);

  const toggleRowExpansion = (row) => {
    const rowId = rowKey ? fetchDataByNestedKey(row.original, rowKey) : null;
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Paper sx={{ width: '100%', mb: 1, overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: maxHeight || '78vh' }} className="dataTable">
          <Table stickyHeader sx={{ position: 'sticky' }} padding={'none'} {...getTableProps()} data-testid={`table-${dataTestId}`}>
            <TableHead>
              {headerGroups.map((headerGroup, index) => {
                const { key, ...restProps } = headerGroup.getHeaderGroupProps();
                return (<TableRow key={`${index}-${key}`} {...restProps}>
                  {allowSelect && (
                    <TableCell className="table-head-cell" style={{ width: '1rem' }}>
                      <Checkbox
                        indeterminate={Object.keys(selectedRowIds).length > 0 && Object.keys(selectedRowIds).length < rows.length}
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  )}
                  {headerGroup.headers.map((column, columnIndex) =>
                    <DraggableColumnHeader
                      key={column.id}
                      column={column}
                      columnIndex={columnIndex}
                      updateColumnOrder={updateColumnOrder}
                      allowSorting={allowSorting}
                    />
                  )}
                  {isCollpsable && (
                    <TableCell className="table-head-cell" align="center">
                      {collapsibleColumnLabel}
                    </TableCell>
                  )}
                </TableRow>)
              })
              }
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.length === 0 ? (
                // Check if data is empty
                <TableRow>
                  <TableCell
                    data-testid={'no-data-content'}
                    className="table-row-cell"
                    colSpan={columns?.length + (isCollpsable ? 1 : 0)}
                    align="center"
                    style={{ borderBottom: 'none' }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (rows.map((row, i) => {
                prepareRow(row);
                if (isDraggable) {
                  return (
                    <DraggableRow
                      key={row.id}
                      row={row}
                      index={i}
                      moveRow={moveRow}
                      prepareRow={prepareRow}
                      handleRowSelect={handleRowSelect}
                      selectedRowIds={selectedRowIds}
                      uniqueKey={uniqueKey}
                      allowSelect={allowSelect}
                      isCollpsable={isCollpsable}
                      expandedRows={expandedRows}
                      toggleRowExpansion={toggleRowExpansion}
                      rowKey={rowKey}
                      fetchDataByNestedKey={fetchDataByNestedKey}
                      ChildComponent={ChildComponent}
                    />
                  );
                } else {
                  const rowId = isCollpsable && fetchDataByNestedKey(row.original, rowKey);
                  const isExpanded = expandedRows.includes(rowId) || isChildComponentExpanded;
                  return (
                    <CollapsibleRow
                      key={row.id}
                      row={row}
                      rowKey={rowKey}
                      rowId={rowId}
                      isExpanded={isExpanded}
                      toggleRowExpansion={toggleRowExpansion}
                      fetchDataByNestedKey={fetchDataByNestedKey}
                      ChildComponent={ChildComponent}
                      columnsLength={row.cells.length + (isCollpsable ? 1 : 0) + (allowSelect ? 1 : 0)}
                      allowSelect={allowSelect}
                      uniqueKey={uniqueKey}
                      handleRowSelect={handleRowSelect}
                      selectedRowIds={selectedRowIds}
                      isCollpsable={isCollpsable}
                      isChildComponentExpanded={isChildComponentExpanded}
                    />
                  );
                }
              })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination ? <TablePagination onPageChange={onPageChange} rowsCount={totalRowsCount} page={page} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} /> : null}
      </Paper>
    </DndProvider>
  );
};

export default DataTable;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { Box, Grid, Alert, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { InputField } from 'component/common/Input/Input.jsx';
import { PrimaryButton } from 'component/common/Button/Button.jsx';
import { AuthLayout } from 'component/common/Layout/AuthLayout.jsx';
import { ResponseModal } from 'component/common/Modal/ResponseModal.jsx';
import { fetchUserData, forgotPassword, generateOTP } from 'features/auth/authAction';
import { Loader } from 'component/common/Loader/Loader';
import { validateForm } from 'utils/formValidator';
import { transformedErrors, resetLocalStorage } from 'utils/authHelper';
import { resetError } from 'features/auth/authSlice';
import { urls } from 'utils/urls';
import './login.scss';

export default function ForgetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const methods = useForm();

    const [formErrors, setFormErrors] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [modalProps, setModalProps] = useState(null);
    const [selectedOption, setSelectedOption] = useState('tfa');

    const { error, loading, success, tempUserData, otpSuccess } = useSelector((state) => state?.auth);

    useEffect(() => {
        if (error) {
            if (typeof (error) === 'string') {
                setErrorMessage(error);
            }
            else {
                setFormErrors(error)
            }
        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps({
                title: 'Password Updated Successfully!',
                message: 'Please login to continue.',
                open: true,
            });

            const timer = setTimeout(() => {
                setModalProps(null);
                resetLocalStorage();
                dispatch(resetError());
                navigate(urls.LOGIN_URL); // redirect to login
            }, 2000);

            return () => clearTimeout(timer); // Proper cleanup function
        }
    }, [success]);

    useEffect(() => {
        if (otpSuccess) {
            setModalProps({
                title: 'OTP Sent Successfully',
                message: otpSuccess,
                open: true,
            });

            const timer = setTimeout(() => {
                setModalProps(null);
            }, 2000);

            return () => clearTimeout(timer); // Proper cleanup function
        }
    }, [otpSuccess]);

    useEffect(() => {
        setErrorMessage(null);
        if (selectedOption === 'otp' && tempUserData) {
            dispatch(generateOTP({ entityId: tempUserData?.userId, entityType: 'User' }));
        }
    }, [selectedOption]);

    useEffect(() => {
        if (tempUserData && !tempUserData?.tfaEnabled) {
            setSelectedOption('otp')
        }
    }, [tempUserData]);

    // Form submission logic
    const onSubmit = (data) => {
        const { username, newPassword, confirmPassword, otp } = data;
        setFormErrors(null);
        setErrorMessage(null);

        if (tempUserData) {
            if (newPassword !== confirmPassword) {
                return setErrorMessage('New Password and Confirm Password do not match');
            }
            dispatch(forgotPassword({
                login: username,
                newPassword,
                [selectedOption === 'otp' ? 'otp' : 'tfa']: otp,
                type: selectedOption === 'otp' ? 'OTP' : 'TFA',
            }));
        } else {
            const errors = validateForm({ username });
            errors?.length ? setFormErrors(transformedErrors(errors)) : dispatch(fetchUserData(username));
        }
    };

    return (
        <AuthLayout>
            <Grid item xs={6} className='loginForm'>
                <div className='loginFormmWrap'>
                    <Box>
                        <h1>Forget Password</h1>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                {tempUserData ? (
                                    <>
                                        {tempUserData?.tfaEnabled && (
                                            <RadioGroup
                                                row
                                                className='radioButton'
                                                value={selectedOption}
                                                onChange={(e) => setSelectedOption(e.target.value)}
                                                sx={{ alignItems: 'baseline' }}
                                            >
                                                <span>Verify Using</span>
                                                <FormControlLabel
                                                    value="tfa" data-testid='2fa' control={<Radio />} label="2FA Code" />
                                                <FormControlLabel
                                                    value="otp" data-testid='otp' control={<Radio />} label="OTP" />
                                            </RadioGroup>
                                        )}
                                        <InputField name="otp" type="password" placeholder={selectedOption === "tfa" ? "2FA Code" : "OTP"} formErrors={formErrors} maxLength={8} />
                                        <InputField name="newPassword" type="password" placeholder="New Password" formErrors={formErrors} maxLength={60} />
                                        <InputField  name="confirmPassword" type="password" placeholder="Confirm Password" formErrors={formErrors} maxLength={60} />
                                    </>
                                ) : (
                                    <InputField name="username" type="text" placeholder="Username" formErrors={formErrors} maxLength={254} />
                                )}
                                <div className='btnModule'>
                                    <PrimaryButton type="submit" label="Submit" data-testid="submit-btn" />
                                </div>
                            </form>
                            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                        </FormProvider>
                        <div className='loginSupport'>
                            <p>If you are having trouble please contact<br></br>
                                <a href="mailto:support@koorier.ca">support@koorier.ca</a>
                            </p>
                        </div>
                    </Box>
                </div>
            </Grid>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </AuthLayout>
    );
}
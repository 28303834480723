import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { InputField } from 'component/common/Input/Input.jsx'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { AuthLayout } from 'component/common/Layout/AuthLayout.jsx'
import { login } from 'features/auth/authAction'
import { Loader } from 'component/common/Loader/Loader'
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
import { urls } from 'utils/urls'

import './login.scss';

export default function Login(props) {
	const methods = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formErrors, setFormErrors] = useState(null);
	const [loginError, setLoginError] = useState(null);
	const [confirmationModalProps, setConfirmationModalProps] = useState(null);

	const { token, error, loading, passwordExpiryMessage } = useSelector(
		(state) => state?.auth
	)

	useEffect(() => {
		if (token && !passwordExpiryMessage) {
			navigate(urls?.HOME_WIDGET_URL);
			methods.reset();
		}
		if (token && passwordExpiryMessage) {
			setConfirmationModalProps(
				{
					title: 'Password Expired',
					message: passwordExpiryMessage,
					open: true,
					onConfirm: handleRedirectToPasswordReset,
					onClose: handleConfirmationClose
				}
			)
		}
	}, [token, passwordExpiryMessage]);

	useEffect(() => {
		if (error) {
			if (typeof (error) === 'string') {
				setLoginError(error);
			}
			else {
				setFormErrors(error)
			}
		}
	}, [error]);

	const handleConfirmationClose = () => {
		setConfirmationModalProps(null)
	}
	
	const handleRedirectToPasswordReset = () => {
		setConfirmationModalProps(null)
		navigate(urls?.RESET_PASSWORD_URL);
	}

	const onSubmit = (data) => {
		setFormErrors(null)
		setLoginError(null)
		dispatch(login(data));
	}

	const handleForgotPasswordClick = (e) => {
		e.preventDefault();  // Prevent default behavior (navigation)
		navigate(urls?.FORGET_PASSWORD_URL);
	  };

	return (
		<AuthLayout>
			<Grid item xs={6} className='loginForm'>
				<div className='loginFormmWrap'>
					<Box>
						<h1>Login</h1>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)}>
								<InputField name="username" placeholder={'Username'} formErrors={formErrors} maxLength={50} />
								<InputField name="password" type="password" placeholder={'Password'} formErrors={formErrors} maxLength={50} />
								<InputField name="validationCode" placeholder={'2FA Code'} formErrors={formErrors} maxLength={6} required={false} />

								<div className="forgetLink">
									{/* <span className="reg-link">Don’t have account? <a href='/register'>Sign up</a></span> */}
									<span className="reg-link">Generate New <a href='/generateQRCode'> QR Code</a></span>
									<span className='fp-link'><a href={urls?.FORGET_PASSWORD_URL} data-testid='forgot-password' onClick={handleForgotPasswordClick}>Forget Password</a></span>
								</div>
								<div className='btnModule'>
									<PrimaryButton type="submit" label={'Login'} data-testid='login-btn' />
								</div>
							</form>
							{loginError ? <><br /><Alert severity="error">{loginError}</Alert></> : null}
						</FormProvider>
						<div className='loginSupport'>
							<p>If you are having trouble please contact<br></br>
								<a href="mailto:support@koorier.ca">support@koorier.ca</a>
							</p>
						</div>
					</Box>
				</div>
			</Grid>
			{confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
			{loading ? <Loader isLoading={loading} /> : null}
		</AuthLayout>
	)
}
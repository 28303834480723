import React, { useMemo } from 'react'
import { Icon } from '@mui/material';
import { LiveTrackingMap } from "component/liveTracking/liveTrackingMap1";
import './routeDetailsComponent.scss'

const TextComponent = ({ text, count, color }) => {
    return (
        <div className='text'>
            <span>{text}</span>{text ? `:` : ''}<span className='count' style={{ '--font-color': color }}>{count}</span>
        </div>
    );
};


const ComponentWithIcon = ({ icon, text, color, count, countColor }) => {
    return (
        <div className={'row-container'} style={{ '--component-color': color }}>
            {icon ? <>       <Icon sx={{ height: '35px' }}>{icon}</Icon>
                <div className={'vertical-bar'}></div>
            </> : null
            }
            <TextComponent text={text} count={count} color={countColor}></TextComponent>
        </div>
    );
};

export const RouteMapComponent = React.memo(({ routeDetail, height }) => {
    //Using momoize component to render map, as map will through error if we render on every state change
    const memoizedComponent = useMemo(() => {
        const center = { dcLat: routeDetail?.distributionCenterResponseVm?.dcLat, dcLon: routeDetail?.distributionCenterResponseVm?.dcLon }
        return <LiveTrackingMap height={height} showStops={true} routeStops={routeDetail?.routeStops || []} center={center} />
    }, [routeDetail])

    const data = [
        {
            text: 'Total Tote',
            count: routeDetail?.dailyRouteScanSummaryVM?.totalTotes,
            icon: <img src="static/images/RETURNS-DASHBOARD.svg" alt='Total Tote' />,
            color: '#CEC7FF',
            countColor: '#6A5EBD',
        },
        {
            text: 'Oversized-Parcels',
            count: routeDetail?.dailyRouteScanSummaryVM?.totalOversizedPackages,
            icon: <img src="static/images/RETURNS-DASHBOARD.svg" alt='Oversized Parcels' />,
            color: '#C6EAFF',
            countColor: '#608EA8',
        },
        {
            text: 'Total Distance',
            count: `${routeDetail?.dailyRouteScanSummaryVM?.totalDistance || ''} ${routeDetail?.dailyRouteScanSummaryVM?.distanceUnit || ''}`,
            icon: <img src="static/images/RETURNS-DASHBOARD.svg" alt='Total Distance' />,
            color: '#FFD6E8',
            countColor: '#AC5D7F',
        },
        {
            text: 'Total Time',
            count: `${routeDetail?.dailyRouteScanSummaryVM?.totalTime || ''}`,
            icon: <img src="static/images/RETURNS-DASHBOARD.svg" alt='Total Time' />,
            color: '#ADE8A9',
            countColor: '#6A5EBD',
        },
        {
            text: 'Route Sequencing Time',
            count: `${routeDetail?.dailyRouteScanSummaryVM?.routeSequencingTime || ''}`,
            icon: <img src="static/images/RETURNS-DASHBOARD.svg" alt='Sequencing Time' />,
            color: '#DAF2D8',
            countColor: '#2E632B',
        },
        {
            text: 'Actual Service Time',
            count: `${routeDetail?.dailyRouteScanSummaryVM?.actualRouteServiceTime || ''}`,
            icon: <img src="static/images/RETURNS-DASHBOARD.svg" alt='Service Time' />,
            color: '#DAF2D8',
            countColor: '#2E632B',
        }
    ]

    return (
        <div className='route-detail-container' >
            <div className='route-detail-row'>
                {
                    data?.map((item) => {
                        return (<ComponentWithIcon key={item?.text} icon={item?.icon} text={item?.text} count={item?.count} color={item?.color} countColor={item?.countColor} />)
                    })
                }
            </div>
            {memoizedComponent}
        </div>
    )
})

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import DataTable from 'component/dataTable/dataTable'
import ScheduleJobData from 'data/scheduleJob.json'
import { PrimaryButton } from 'component/common/Button/Button';
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal';
import { ResponseModal } from 'component/common/Modal/ResponseModal';
import { resetSuccessState } from 'features/packages/packagesSlice';
import { sendSLAEmailViolation } from 'features/packages/packagesAction';
import { Loader } from 'component/common/Loader/Loader';

export const ScheduleJobs = () => {

    const dispatch = useDispatch()

    const { loading, updatePackageSuccess, successMessage } = useSelector(
        (state) => state?.packages
    )

    const [modalProps, setModalProps] = useState(null)
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);


    useEffect(() => {
        if (updatePackageSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: successMessage ? successMessage : 'Email Sent Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetSuccessState())
                setModalProps(null)
                setConfirmationModalProps(null)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updatePackageSuccess]);

    const handleSLAViolation = () => {
        setConfirmationModalProps({
            title: `SLA Violation`,
            message: `Send SLA Violation mail`,
            open: true,
            onConfirm: () => dispatch(sendSLAEmailViolation()),
            onClose: handleConfirmationClose
        });
    }

    const handleConfirmationClose = () => {
        setConfirmationModalProps(null)
    }


    const columns = [{
        accessor: "jobName",
        title: "Scuedule Job Name",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.jobName
    },
    {
        accessor: "time",
        title: "Running Time",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.time
    }
    ]

    return (
        <Layout headerTitle={'Schedule Jobs'} showBackArrow={false} action={<PrimaryButton label={"SLA Violation"} height={"30px"} onClick={handleSLAViolation} />}>
            <div className='container'>
                <div className='content'>
                    <DataTable columns={columns} data={ScheduleJobData || []} isCollpsable={false} showPagination={false} uniqueKey={'jobName'} />
                </div>
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}

        </Layout>

    )
}
import React from 'react';
import { TablePagination as MUITablePagination } from '@mui/material';

export const TablePagination = ({ onPageChange, page, rowsCount, handleChangeRowsPerPage, rowsPerPage }) => {
    const rowsPerPageOptions = [10, 25, 50, 100];

    return (
        <MUITablePagination component="div" data-testid={`table-pagination`}
            sx={{
                '.MuiToolbar-root': {
                    position: 'static',
                    zIndex: 1,  // this is need to allow click even on overlapping menu
                },
            }}
            count={parseInt(rowsCount, 10)} onPageChange={onPageChange} page={page} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} rowsPerPageOptions={rowsPerPageOptions} />
    );
};